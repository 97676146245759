<template>
  <div class="index-wrap">
    <!-- <div class="head-box"> -->
    <div class="logo_view">
      <img class="logo" src="../assets/qjjp/logo.png" alt="" srcset="" />
      <span>月记管家</span>
    </div>

    <div class="view_background">
      <div :style="{ width: '100%', height: '1px' }"></div>
      <img class="view_bg1" src="../assets/qjjp/view_bg1.png" alt="" srcset="" />
      <div class="down_button" @click="down">
        <span>Android版下载</span>
      </div>
    </div>
    <div class="view_background">
      <div :style="{ width: '100%', height: '1px' }"></div>
      <img class="view_bg2" src="../assets/qjjp/view_bg2.png" alt="" srcset="" />
    </div>
    <div class="view_background">
      <div :style="{ width: '100%', height: '1px' }"></div>
      <img class="view_bg3" src="../assets/qjjp/view_bg3.png" alt="" srcset="" />
    </div>
    <div class="view_background">
      <div :style="{ width: '100%', height: '1px' }"></div>
      <img class="view_bg4" src="../assets/qjjp/view_bg4.png" alt="" srcset="" />
      <div class="ba">
        {{ info.dz }}<br />
        {{ info.gs }} <a href="https://beian.miit.gov.cn/" style="color: #999999" target="_blank"
          rel="noopener noreferrer">{{ info.ba }}</a> 联系我们：4009990313
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
// import qs from "qs";
export default {
  components: {
    // downFixe:()=> import('@/view/downFixe')
  },
  data() {
    return {
      activeIndex: 0,
      bannerIndex: 0,
      leftPx0: "0",
      leftPx1: "0",
      leftPx2: "0",
      topPic8: 0,
      topPic5: 0,
      topFooter: 0,
      check: 0,
      info: {
        dz: '中国（四川）自由贸易试验区成都高新区天府大道中段1366号2栋11层22一26号',
        gs: '成都优悦数创科技有限公司',
        ba: '蜀ICP备2020030824号-14'
      }
    };
  },
  methods: {
    getBa() {
      let host = location.host
      switch (host) {
        case 'cdyicang.cn':
          this.info = {
            dz: '中国（四川）自由贸易试验区成都高新区萃华路89号、吉泰一街99号、升华路108号1栋1单元6楼605号',
            gs: '成都壹沧信息科技有限公司',
            ba: '蜀ICP备2024092813号-1'
          }
          break;
        case 'www.cdyicang.cn':
          this.info = {
            dz: '中国（四川）自由贸易试验区成都高新区萃华路89号、吉泰一街99号、升华路108号1栋1单元6楼605号',
            gs: '成都壹沧信息科技有限公司',
            ba: '蜀ICP备2024092813号-1'
          }
          break;
        case 'huitongyongyao.com':
          this.info = {
            dz: '四川省成都市武侯区永顺路49号（自编号附2418号）',
            gs: '成都辉通永曜科技有限公司',
            ba: '蜀ICP备2024093247号-1'
          }
          break;
        case 'www.huitongyongyao.com':
          this.info = {
            dz: '四川省成都市武侯区永顺路49号（自编号附2418号）',
            gs: '成都辉通永曜科技有限公司',
            ba: '蜀ICP备2024093247号-1'
          }
          break;
        default:
          this.info = {
            dz: '中国（四川）自由贸易试验区成都高新区天府大道中段1366号2栋11层22一26号',
            gs: '成都优悦数创科技有限公司',
            ba: '蜀ICP备2020030824号-14'
          }
          break;
      }
    },
    get(url) {
      const config = {
        method: 'get',
        url: url,
        headers: {
          'siteId': 'yjc510262a4346bf9fc5b49a2b81612f', // 添加认证令牌
        }
      };
      return new Promise((resolve, reject) => {
        axios(config)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    down() {
      this.get("/version/getNewestUrl").then((res) => {
        if (res.code == 200) {
          location.href = res.data;
        }
      });
    },

    handlePre() {
      this["leftPx" + this.bannerIndex] = "0";
    },
    handleNext() {
      this["leftPx" + this.bannerIndex] = "-385px";
    },
    topScroll(i) {
      let top = 0;
      this.check = i;
      switch (i) {
        case 0:
          top = 0;

          break;
        case 1:
          top = this.topPic8;
          break;
        case 2:
          top = this.topPic5;
          break;
        case 3:
          top = this.topFooter;
          break;
      }
      window.scrollTo({
        top,
        behavior: "smooth",
      });
    },
    scleTop() {
      this.topPic8 = this.$refs.pic8.offsetTop - 80;
      this.topPic5 = this.$refs.pic5.offsetTop - 87;
      this.topFooter = this.$refs.footer.offsetTop - 87;
    },
    downOpenInstall() {
      var data = window.OpenInstall.parseUrlParams(); // /openinstall.js中提供的工具函数，解析url中的所有查询参数
      new window.OpenInstall(
        {
          /* appKey必选参数，openinstall平台为每个应用分配的ID*/
          appKey: "w394os",
          /* 自定义遮罩的html*/
          // mask:function(){
          //  return "<div id='_shadow' style='position:fixed;left:0;top:0;background:rgba(0,255,0,0.5);filter:alpha(opacity=50);width:100%;height:100%;z-index:10000;'></div>"
          // },
          /* OpenInstall初始化完成的回调函数，可选*/
          onready: function () {
            /* 在app已安装的情况尝试拉起app*/
            this.schemeWakeup();

            /* 用户点击某个按钮时(假定按钮id为downloadButton)，安装app*/
            var m = this;
            m.wakeupOrInstall();
          },
        },
        data
      );
    },
  },
  mounted() {
    axios.defaults.baseURL = "https://api.jingximh.net/major";
    this.getBa()
  },
};
</script>

<style lang="scss">
.logo_view {
  position: fixed;
  top: 18px;
  left: 120px;
  z-index: 2;

  .logo {
    width: 60px;
    height: 60px;
    vertical-align: middle;
  }

  span {
    padding-left: 4px;
    vertical-align: middle;
    font-family: HelloFont, HelloFont;
    font-weight: normal;
    font-size: 32px;
    color: #222222;
    line-height: 40px;
    text-align: center;
    font-style: normal;
  }
}

.view_background {
  background-image: url("../assets/qjjp/view_background.png");
  width: 100%;
  height: 1080px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  .ba {
    margin: 34px auto 0;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #999999;
    line-height: 22px;
    letter-spacing: 2px;
    text-align: center;
    font-style: normal;
  }

  .down_button {
    cursor: pointer;
    width: 152px;
    height: 55px;
    background: #FF5CA1;
    border-radius: 8px;
    margin: 42px auto 0;
    text-align: center;
    position: absolute;
    top: 614px;
    left: 124px;

    span {
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      font-size: 18px;
      color: #FFFFFF;
      line-height: 55px;
      text-align: center;
      font-style: normal;
    }
  }

  .view_bg1 {
    width: 1473px;
    height: 851px;
    margin: 107px 0 0 120px;
  }

  .view_bg2 {
    width: 1575px;
    height: 907px;
    margin: 51px 0 0 120px;
  }

  .view_bg3 {
    width: 1569px;
    height: 821px;
    margin: 137px 0 0 120px;
  }

  .view_bg4 {
    width: 1527px;
    height: 768px;
    margin: 190px 0 0 120px;
  }
}

.index-wrap {

  // padding-top: 87px;

}

.down_box {
  position: relative;

  .fix_down {
    position: absolute;
    bottom: 105px;
    left: 936px;
    z-index: 999;
    text-align: left;

    .down_btn {
      display: inline-block;
      width: 120px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      border-radius: 12px;
      color: #fff;
      background-image: linear-gradient(to bottom, #ff811e, #ff5a01);
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      cursor: pointer;
    }

    .down_txt {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
  }
}

.head-box {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 87px;
  background: #ffffff;
  padding: 0 400px;

  .logo {
    width: 180px;
    height: 43px;
  }

  .nav-box {
    display: flex;
    align-items: center;

    .nav-list {
      font-size: 24px;
      color: #1a1a1a;
      margin-left: 91px;
      cursor: pointer;
      white-space: nowrap;

      img {
        margin: 5px auto 0;
        display: block;
        width: 38px;
        height: 6px;
      }
    }
  }
}

.center {
  text-align: center;
}

.banner,
.pic_7 {
  width: 100%;
}

.pic_6 {
  width: 936px;
  margin-top: 29px;
}

.pic_7 {
  margin-top: 45px;
}

.pic_8 {
  width: 1140px;
  margin-top: 36px;
}

.pic_8_2 {
  width: 990px;
}

.ewm {
  width: 156px;
  height: 156px;
  position: absolute;
  top: 265px;
  left: 377px;
}

.app_down {
  position: relative;
  width: 990px;
  margin: 156px auto 0;
}

.pic_down {
  width: 375px;
  position: absolute;
  top: 454px;
  left: 377px;
  cursor: pointer;
}

.banner-wrap {
  .banner-title {
    margin-top: 118px;
  }

  .banner-nav {
    width: 1140px;
    margin: 43px auto 0;
    display: flex;
    align-items: center;

    .b-n-l {
      flex: 1;
      text-align: center;
      color: #f64847;
      font-size: 24px;
      cursor: pointer;
    }

    .active {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 137px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px;
        height: 3px;
        background: linear-gradient(270deg, #ff6f6f 0%, #f64847 100%);
        border-radius: 2px;
      }
    }

    .b-n-l-4 {
      &:after {
        width: 78px;
      }
    }
  }

  .banner-box {
    width: 1140px;
    margin: 28px auto 0;

    .banner-ls-box-h {
      width: 100%;
      overflow: hidden;
    }

    .banner-ls-box {
      display: flex;
      transition: 0.3s;

      img {
        width: 360px;
        height: 428px;
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .next-pre {
      width: 100%;
      position: relative;
      z-index: 1;

      img {
        position: absolute;
        width: 74px;
        height: 74px;
        top: 155px;
        cursor: pointer;
      }

      .icon_last {
        left: -37px;
      }

      .icon_next {
        right: -37px;
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 111px;
  background: #ff4910;
  height: 462px;
  padding: 0 435px 0 443px;
  width: 100%;

  .pic {
    width: 376px;
    height: 429px;
  }

  .des-box {
    width: 595px;

    .footer-title {
      font-size: 47px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    p {
      font-size: 28px;
      color: #ffffff;
      line-height: 55px;
      white-space: nowrap;
    }

    .icp {
      font-size: 23px;
    }
  }
}
</style>
